import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0028 from '../../../components/molecules/columnDetailInner0028'
import ColumnRelationCards0028 from '../../../components/molecules/columnRelationCards0028'
import UsefulFunctionLayout012 from '../../../components/molecules/usefulFunctionLayout012'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0028 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        'みなし残業とはどのような制度？みなし労働時間制との違いやメリット・注意点',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="みなし残業とは？間違えやすいみなし労働時間制との違いまで解説"
        description="みなし残業とは、会社から社員へ支払われる給与額に一定時間分の残業代を定額で上乗せして支払う制度です。別名「固定残業制」「定額残業制」などと呼ばれます。"
        ogUrl="https://kintaicloud.jp/column/details/C0028/"
        ogType="article"
        ogTitle="みなし残業とは？間違えやすいみなし労働時間制との違いまで解説"
        ogDescription="みなし残業とは、会社から社員へ支払われる給与額に一定時間分の残業代を定額で上乗せして支払う制度です。別名「固定残業制」「定額残業制」などと呼ばれます。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0028.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0028 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout012 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0028 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0028

export const pageQuery = graphql`
  query C0028 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
