import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction002 from './Introduction002'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0028 = () => (
  <Wrapper>
    <ColumnH1 label="みなし残業とは？間違えやすいみなし労働時間制との違いまで解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        残業時間に関するトラブルは後を絶たず、依然として社会問題化しています。しかし、社員の残業時間の把握や残業代の計算作業は正確性が必要とされ、時間がかかる大変な業務でもあります。
        <br />
        このような状況に対応するための制度として<span>「みなし残業」</span>
        があります。今回は、みなし残業の内容や間違えやすい
        <span>「みなし労働時間制」</span>
        との違い、実際に導入した場合の効果や注意点について、順を追って解説をしていきましょう。
      </p>
      <img src="/images/column/details/c0028.jpg" alt="キンクラコラム画像28" />
      <h1>「みなし残業」とは何か</h1>
      <p>
        みなし残業制とは、会社から社員へ支払われる給与額に一定時間分の残業代を定額で上乗せして支払う制度で、別名で「固定残業制」「定額残業制」などと呼ばれる場合もあります。
        <br />
        <br />
        みなし残業制が活用される例としては、例えば外回りの多い営業職などのケースが挙げられます。
        <br />
        内勤の社員の場合は、タイムカードなどで勤怠時間を管理することが容易であることから、記録内容をもとに残業代の計算をすることができます。一方、直行・直帰が当たり前の営業職の場合、何時から何時まで働いたかという区分があいまいとなり、残業代の計算が非常に困難となります。
        <br />
        このような場合にみなし残業制を活用し、会社側があらかじめ外回りの社員の
        <span>残業時間を見込んだ金額を毎月支払う</span>
        ことで、不明瞭な残業時間を計算し、支払う代わりとすることができるのです。
        <br />
        <br />
        なお、みなし残業代は、あくまでも毎月定額で支払うものとなるため、例えば実際の残業時間がみなし残業として設定した時間よりも短かった場合でも、設定した定額分を支払わなければなりません。
      </p>
      <h1>みなし労働時間制との違い</h1>
      <p>
        みなし残業と似たような言葉に<span>「みなし労働時間制」</span>
        というものがあります。いずれも「みなし」という表現が含まれていることから混同されがちですが、実はこの2種は大きく異なる点に注意が必要です。
        <br />
        <br />
        みなし労働時間制とは、会社が社員の労働時間を正確に把握することができないような場合に
        <span>一定時間の労働をしたものとみなし</span>
        、社員の労働時間を把握する義務を免れることができる制度です。
        <br />
        <br />
        みなし労働時間制の詳細は労働基準法という法律で厳格に定められており、一定の要件を満たさない場合は実施することができません。制度を導入する場合は法律内容を熟知した上で実施する必要があります。
        <br />
        <br />
        一方、みなし残業は、みなし労働時間制とは異なり法律による規定はありません。また、制度を導入する際の一定要件もないことから、どの業種の企業でも、どの職種の社員に対しても導入する点がみなし労働時間制とは異なります。
        <br />
        <br />
        みなし労働時間制には、次の種類が挙げられます。
      </p>
      <h2>①事業場外労働のみなし労働時間制</h2>
      <p>
        社外での業務が多く、労働時間の管理をすることが難しい社員に対し、原則として所定労働時間分を働いたものとみなす方法です。
      </p>
      <h2>②専門業務型裁量労働制</h2>
      <p>
        専門性が高く、仕事の方法や内容、時間配分などを社員の裁量に委ねざるを得ない内容であるとして法律で定められた業務に従事する社員について、労使協定により前もって定めた時間分を働いたものとしてみなす方法です。
      </p>
      <h2>③企画業務型裁量労働制</h2>
      <p>
        人事労務や財務、広報部など、会社の事業を運営するための企画から立案、調査や分析をする部署において、仕事の方法や内容、時間配分などを社員の裁量に委ねざるを得ない内容であるとして法律で定められた業務に従事する社員について、労使委員会で前もって定めた時間分を働いたものとしてみなす方法です。
      </p>
      <Introduction002 />
      <h1>みなし残業制による効果</h1>
      <p>
        みなし残業制を導入した場合の企業におけるメリットとしては、何よりもまず残業代の計算という業務にかける時間を削減できる点です。
        <br />
        残業代は、厳密に言うと法定労働時間を1分でもオーバーした場合に支払う必要性が生じます。社員一人ひとりの残業代を毎月のように洗い出し、計算を行う行為は、会社にとってかなりの労力を伴うものですが、残業代の計算や支払を怠ったことによる労使トラブルも後を絶たず、会社の安定した存続にとっても避けては通れない、重要な内容であることは疑いようもありません。
        <br />
        このようなケースに対応するため、みなし残業時間制は非常に有効な方法です。みなし残業時間制により一定時間分の残業代を加味しておけば、
        <span>
          設定時間を超える残業が行われない限り、煩雑な残業代の計算作業から免れること
        </span>
        ができます。
        <br />
        <br />
        また、みなし残業制の導入は、新規社員の募集・採用時にも効果を発揮します。募集要項に記載する賃金額を固定残業代込みの金額にすることで、求職者は通常の基本給に上乗せされた金額を目にすることになり、興味を持つ確率がアップするといわれています。
        <br />
        ただし、みなし残業制を導入する場合は、
        <span>
          基本給と固定残業代を明確に区分けし、何時間分の残業代が含まれているかを明らかにする
        </span>
        必要があります。あいまいな表記で固定残業代込みの金額を「給料です」と提示することは、法律に問われトラブルに発展する可能性があるため避けるようにしましょう。
      </p>
      <h1>みなし残業制の注意点</h1>
      <p>
        みなし残業制を導入する場合に注意しなければならない点としては、前述の通り基本給と固定残業代の区分や残業したものとみなす時間を明らかにすることです。
        <br />
        基本給とみなし残業代の内訳が不明瞭な場合、社員はみなし残業の対象が何時間までなのかが分からず、会社の隠ぺいを疑いトラブルになる危険性があります。また、みなし残業時間に満たなかった場合も、絶対に固定残業代をカットしてはいけません。
        <br />
        <br />
        その他、みなし残業時間を長すぎる時間で設定することも無効と扱われる危険性があるため、注意が必要です。その理由としては、法律により法定労働時間をオーバーした時間外労働の上限時間が定められていることが挙げられます。
        <br />
        具体的な時間は、<span>1ヶ月あたり45時間、一年あたり360時間</span>
        です。この時間を超えて時間外労働をさせるには、相当の臨時性が高い特別な事情が必要となるため、みなし残業時間として45時間を超える時間を設定することは法律に反することと扱われる可能性があります。
      </p>
      <h1 className="read">まとめ</h1>
      <p>
        みなし残業が、みなし労働時間制とは別の制度であるということがお分かりいただけたかと思います。みなし残業制には、社内業務の効率化などというメリットもありますが、実際に導入する際には法律内容を確認するなどの慎重な対応が求められます。
        <br />
        まずは社内の実態を洗い出し、導入した場合の効果・懸念点を含めて検討してみてはいかがでしょうか。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0028
